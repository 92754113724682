<template>
    <div class="compare_main">
        <template v-if="$store.state.userInfo.vip_type != 5">
            <div class="header">
                <div class="header_title">竞品对比</div>
                <div class="company_group">
                    <div class="company_item has_border">
                    <span class="item_left">
                        <span class="label">当前公司：</span>
                        <div class="canClick company" @click="changeCompany(1, 64)">
                            <img v-if="searchCompany1.default" src="@/assets/img/search/icon_lock.png" alt="" width="16px" height="18px">
                            <span  class="text">{{searchCompany1.company_name || '加载中...'}}</span>
                            <span style="color: #5ea4fa; margin-right: 8px">切换</span>
                            <img src="@/assets/img/search/icon_change.png" alt="" width="18px" height="14px">
                        </div>
                    </span>
                    </div>
                    <div class="company_item">
                    <span class="item_left">
                        <span class="label">对比公司：</span>
                        <!--                        <div v-if="searchCompany2.company_name_digest" class="canClick company" @click="changeCompany(2)">-->
                        <div v-if="searchCompany2.company_name_digest" class="canClick company" @click="changeCompany(2)">
                            <img v-if="searchCompany2.default" src="@/assets/img/search/icon_lock.png" alt="" width="16px" height="18px">
                            <span  class="text">{{searchCompany2.company_name || '加载中...'}}</span>
                            <span style="color: #5ea4fa; margin-right: 8px">切换</span>
                            <img src="@/assets/img/search/icon_change.png" alt="" width="18px" height="14px">
                        </div>
                        <div v-else>
                            <myButton type="default" width="100" height="33" @click="changeCompany(2)">
                                <i class="el-icon-circle-plus" slot="img" style="color: #1f81f8; margin-right: 5px"></i>
                                <span slot="text">添加企业</span>
                            </myButton>
                        </div>
                    </span>
                    </div>
                </div>
            </div>

            <div class="compare_group">
                <div class="compare_card">
                    <div class="title">推广概览</div>
                    <div class="body_line" v-if="canCompare == 'pie' || canCompare == 'zero'">
                        <div class="left_company">
                            <div class="main_info">
                                <span class="company_name" @click="$C.getCompanyDetails(searchCompany1.company_name_digest)">{{searchCompany1.company_name}}</span>
                                <span class="last_time">最后推广时间 {{searchCompany1.nAdLatestTime}}</span>
                            </div>
                            <div class="percent_group">
                                <div class="percent_item">
                                    <div class="percent_line" :style="{'width': Math.log(searchCompany1.nAdCnt)/Math.log(searchCompany1.nAdCnt+searchCompany2.nAdCnt)*120 +'px'}"></div>
                                    <span class="percent_cnt">{{searchCompany1.nAdCnt}}</span>
                                </div>
                                <div class="percent_item">
                                    <div class="percent_line" :style="{'width': Math.log(searchCompany1.nAdWordCnt)/Math.log(searchCompany1.nAdWordCnt+searchCompany2.nAdWordCnt)*100 +'px'}"></div>
                                    <span class="percent_cnt">{{searchCompany1.nAdWordCnt}}</span>
                                </div>
                                <div class="percent_item">
                                    <div class="percent_line" :style="{'width': Math.log(searchCompany1.nAdLinkCnt)/Math.log(searchCompany1.nAdLinkCnt+searchCompany2.nAdLinkCnt)*50 +'px'}">
                                    </div><span class="percent_cnt">{{searchCompany1.nAdLinkCnt}}</span>
                                </div>
                                <div class="percent_item" style="margin-bottom: 0">
                                    <div v-if="searchCompany1.nAdPlatform" class="percent_line" :style="{'width': searchCompany1.nAdPlatform.length/7*20 +'px'}"></div>
                                    <span v-if="searchCompany1.nAdPlatform" class="percent_cnt">{{searchCompany1.nAdPlatform.length}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="center">
                            <span>推广记录数</span>
                            <span>推广关键词</span>
                            <span>推广域名</span>
                            <span>推广平台</span>
                        </div>
                        <div class="right_company">
                            <div class="main_info">
                                <span class="company_name" @click="$C.getCompanyDetails(searchCompany2.company_name_digest)">{{searchCompany2.company_name}}</span>
                                <span class="last_time">{{searchCompany2.nAdLatestTime}} 最后推广时间</span>
                            </div>
                            <div class="percent_group">
                                <div class="percent_item">
                                    <div class="percent_line" :style="{'width': Math.log(searchCompany2.nAdCnt)/Math.log(searchCompany1.nAdCnt+searchCompany2.nAdCnt)*120 +'px'}"></div>
                                    <span class="percent_cnt">{{searchCompany2.nAdCnt}}</span>
                                </div>
                                <div class="percent_item">
                                    <div class="percent_line" :style="{'width': Math.log(searchCompany2.nAdWordCnt)/Math.log(searchCompany1.nAdWordCnt+searchCompany2.nAdWordCnt)*100 +'px'}"></div>
                                    <span class="percent_cnt">{{searchCompany2.nAdWordCnt}}</span>
                                </div>
                                <div class="percent_item">
                                    <div class="percent_line" :style="{'width': Math.log(searchCompany2.nAdLinkCnt)/Math.log(searchCompany1.nAdLinkCnt+searchCompany2.nAdLinkCnt)*50 +'px'}">
                                    </div><span class="percent_cnt">{{searchCompany2.nAdLinkCnt}}</span>
                                </div>
                                <div class="percent_item" style="margin-bottom: 0">
                                    <div v-if="searchCompany2.nAdPlatform" class="percent_line" :style="{'width': searchCompany2.nAdPlatform.length/7*20 +'px'}"></div>
                                    <span v-if="searchCompany2.nAdPlatform" class="percent_cnt">{{searchCompany2.nAdPlatform.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empty_body" v-else>
                        <img class="empty_img" src="../../../assets/img/compare/empty.png"/>
                        <span  v-if="canCompare == 'no_a'">当前公司暂无推广数据，请切换。<span @click="changeCompany(1)" class="blue canClick">立即切换</span></span>
                        <span  v-if="canCompare == 'no_b'">对比公司暂无推广数据，请切换。<span @click="changeCompany(2)" class="blue canClick">立即切换</span></span>
                        <span  v-if="canCompare == 'no_all'">暂无推广数据，请切换</span>
                        <span v-if="canCompare == 'empty'">当前无对比公司，请添加。 <span @click="changeCompany(2)" class="blue canClick">立即添加</span></span>
                    </div>
                </div>
                <div class="compare_card">
                    <div class="title" @click="test(canCompare)">关键词竞争</div>
                    <span class="switch">
                    <span class="left" :class="pieCompany == 'company2'?'selected':''" @click="changePie('company2')">对比公司</span>
                    <span class="right" :class="pieCompany == 'company1'?'selected':''" @click="changePie('company1')">当前公司</span>
                </span>
                    <div id="body_chart" ref="body_chart" v-show="canCompare == 'pie'"></div>
                    <div class="empty_body" v-show="canCompare !== 'pie'">
                        <img class="empty_img" src="../../../assets/img/compare/empty.png"/>
                        <span  v-if="canCompare == 'no_a'">当前公司暂无推广数据，请切换。<span @click="changeCompany(1)" class="blue canClick">立即切换</span></span>
                        <span  v-if="canCompare == 'no_b'">对比公司暂无推广数据，请切换。<span @click="changeCompany(2)" class="blue canClick">立即切换</span></span>
                        <span  v-if="canCompare == 'no_all'">暂无推广数据，请切换</span>
                        <span  v-if="canCompare == 'zero'">暂无相同关键词</span>
                        <span v-if="canCompare == 'empty'">当前无对比公司，请添加。 <span @click="changeCompany(2)" class="blue canClick">立即添加</span></span>
                    </div>
                </div>
            </div>

            <div class="list_box">
                <div class="title">创意对比</div>
                <div class="key_words">
                    <el-input
                        style="width: 345px;"
                        placeholder="请添加要对比的关键词"
                        @keyup.enter.native="addKeyWorld"
                        v-model="newKeyWorld">
                        <template #append>
                        <span class="input_button" @click="addKeyWorld">
                            <el-button>添加</el-button>
                            <i class="el-icon-plus"></i>
                        </span>
                        </template>
                    </el-input>
                    <div class="tag_groups">
                        <el-tag
                            v-for="(item, i) in searchKeyWords"
                            :key="i"
                            closable
                            @close="handleClose(item)"
                            type="info">
                            {{item}}
                        </el-tag>
                    </div>
                </div>
                <div class="list_head">
                <span>找到<span v-if="!searchKeyWords.length">相同</span>关键词
                    <span v-if="searchCompany2.company_name_digest" class="red">{{totalPageConfig.total >= 100?'100+':totalPageConfig.total}}</span>
                    <span v-else>-</span>
                个</span>
                    <myButton type="default" width="100" height="33" class="button" @click="exportList('list')">
                        <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                        <span slot="text">立即导出</span>
                    </myButton>
                </div>
                <div class="list_body">
                    <div class="table-content"  v-show="searchCompany2.company_name_digest">
                        <el-table
                            v-loading="tableLoading"
                            border
                            :data="tableData"
                            :cell-style="tableCellStyle"
                            style="width: 100%">
                            <el-table-column
                                min-width="200"
                                label="关键词">
                                <template slot-scope="scope">
                                    <div style="text-align: center">
                                        {{scope.row.same_word}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="600">
                                <template #header>
                                    <span class="gray" style="margin: 0 5px">推广平台：</span>
                                    <el-checkbox-group v-model="platformList" @change="platformListChange" style="display: inline-flex; justify-content: space-between; width: 80%">
                                        <el-checkbox :label="1">
                                            <img src="@/assets/img/companyDetails/baidu_search.png" alt="">
                                            <span class="text">百度PC</span>
                                        </el-checkbox>
                                        <el-checkbox :label="2">
                                            <img src="@/assets/img/companyDetails/baidu_mobile.png" alt="">
                                            <span class="text">百度移动</span>
                                        </el-checkbox>
                                        <el-checkbox :label="3">
                                            <img src="@/assets/img/companyDetails/360_search.png" alt="">
                                            <span class="text">360PC</span>
                                        </el-checkbox>
                                        <el-checkbox :label="4">
                                            <img src="@/assets/img/companyDetails/360_mobile.png" alt="">
                                            <span class="text">360移动</span>
                                        </el-checkbox>
                                        <el-checkbox :label="5">
                                            <img src="@/assets/img/companyDetails/sougou_search.png" alt="">
                                            <span class="text">搜狗PC</span>
                                        </el-checkbox>
                                        <el-checkbox :label="6">
                                            <img src="@/assets/img/companyDetails/sougou_mobile.png" alt="">
                                            <span class="text">搜狗移动</span>
                                        </el-checkbox>
                                        <el-checkbox :label="7">
                                            <img src="@/assets/img/companyDetails/shenma_search.png" alt="">
                                            <span class="text">神马搜索</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </template>
                                <el-table-column
                                    min-width="300">
                                    <template #header>
                                        <div class="table_company">
                                            <span class="name">{{searchCompany1.company_name}}</span>
                                            <el-select
                                                style="width: 325px"
                                                @visible-change="linkListChange"
                                                @remove-tag="linkListChange"
                                                v-model="linkSelectedList1"
                                                multiple
                                                collapse-tags
                                                placeholder="全部推广域名">
                                                <template #default>
                                                    <div style="text-align: right; margin: 11px 0">
                                                        <span  @click="linkSelectedList1 = linkList1" class="new_button">全部选择</span>
                                                        <span @click="linkSelectedList1 = []" class="new_button">清空选项</span>
                                                    </div>
                                                    <el-option
                                                        v-for="(item, i) in linkList1"
                                                        :key="i"
                                                        :label="item"
                                                        :value="item">
                                                    </el-option>
                                                </template>
                                            </el-select>
                                        </div>
                                    </template>
                                    <template slot-scope="scope">
                                        <listCard
                                            ref="listCardB"
                                            v-if="scope.row.company_a"
                                            @spreadList="spreadList(scope)"
                                            @closeList="closeList(scope)"
                                            :get-params="{
                                          link_list: linkSelectedList1,
                                          word_str: scope.row.same_word,
                                          platform_list: platformList
                                        }"
                                            :is-spread="scope.row.company_a.spread"
                                            :data="scope.row.company_a">
                                        </listCard>
                                        <span v-else></span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    min-width="300">
                                    <template #header>
                                        <div class="table_company">
                                            <span class="name">{{searchCompany2.company_name}}</span>
                                            <el-select
                                                style="width: 325px"
                                                @visible-change="linkListChange"
                                                @remove-tag="linkListChange"
                                                v-model="linkSelectedList2"
                                                multiple
                                                collapse-tags
                                                placeholder="全部推广域名">
                                                <template #default>
                                                    <div style="text-align: right; margin: 11px 0">
                                                        <span  @click="linkSelectedList2 = linkList2" class="new_button">全部选择</span>
                                                        <span @click="linkSelectedList2 = []" class="new_button">清空选项</span>
                                                    </div>
                                                    <el-option
                                                        v-for="(item, i) in linkList2"
                                                        :key="i"
                                                        :label="item"
                                                        :value="item">
                                                    </el-option>
                                                </template>
                                            </el-select>
                                        </div>
                                    </template>
                                    <template slot-scope="scope">
                                        <listCard
                                            ref="listCardB"
                                            v-if="scope.row.company_b"
                                            @spreadList="spreadList(scope)"
                                            @closeList="closeList(scope)"
                                            :get-params="{
                                          link_list: linkSelectedList2,
                                          word_str: scope.row.same_word,
                                          platform_list: platformList
                                        }"
                                            :is-spread="scope.row.company_b.spread"
                                            :data="scope.row.company_b">
                                        </listCard>
                                        <span v-else></span>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                        <page v-if="totalPageConfig.total > 0" :config="totalPageConfig" @pageChange="totalPageChange" @limitChange="totalLimitChange"></page>
                        <div v-if="totalPageConfig.total <= 0 && !dataLoading" class="empty_table">
                            <img src="../../../assets/img/compare/no_common_word.png" height="144" width="187"/>
                            <span style="margin-bottom: 20px">未找到相同关键词</span>
                        </div>
                        <div v-if="dataLoading" class="empty_table">
                            <img src="../../../assets/img/compare/searching.png" height="145" width="188"/>
                            <span style="margin-bottom: 20px">数据生成中.... 请稍候</span>
                        </div>
                    </div>
                    <div class="empty_body" v-show="!searchCompany2.company_name_digest">
                        <img style="margin-top: 140px" src="../../../assets/img/compare/no_company.png" width="187" height="144"/>
                        <span class="gray" style="font-size: 16px">请指定要对比的竞品公司</span>
                    </div>
                </div>
            </div>
        </template>
        <div v-else style="width: 100%; height: 100%; background-color: #fff; overflow: hidden">
            <NoData>
                <img slot="img" style="width: 187px; margin-bottom: 13px; margin-top: 141px" src="../../../assets/img/icon/no_jurisdiction.png"/>
                <div slot="text" style="text-align: center">
                    <div style="font-size: 16px; color: #333333; margin-bottom: 16px">暂无权限</div>
                    <div style="color: #999999; font-size: 14px;">标准版会员可查看您和竞品公司的竞争分析，请联系在线客服开通</div>
                </div>
            </NoData>
        </div>


        <!--弹窗部分-->
        <el-dialog
            custom-class="my_dialog"
            :before-close="beforeClose"
            :visible.sync="showDialog">
            <template #title>
                <span class="my_dialog_title">切换公司</span>
            </template>
            <div class="search_line">
                <el-input
                    placeholder="请输入公司名称"
                    v-model="changedCompanyName"
                    @input="handleSearch()"
                    class="input-with-select">
                    <div slot="prepend">
                        <el-select v-model="searchType" placeholder="请选择" @change="changeSearchType">
                            <el-option label="全部公司" :value="1"></el-option>
                            <el-option label="我的收藏" :value="2"></el-option>
                        </el-select>
                    </div>
                    <div slot="append">
                        <el-button icon="el-icon-search" @click="handleSearch()"></el-button>
                    </div>
                </el-input>
            </div>
            <div class="collection_head" v-if="collectionList.length && !changedCompanyName && changeIndex != 2">
                <img src="@/assets/img/search/icon_collect.png" alt="" width="14" height="14">
                <span>我的收藏</span>
            </div>
            <div class="collection_head" style="height: 54px; margin-top: 2px" v-if="changeIndex == 2 && !(searchType == 1 && changedCompanyName)">
                <div class="dialog_btn" :class="dialogSelect == 'compete'?'selected':''" @click="changeDialogSelect('compete')">
                    推广竞品
                </div>
                <div class="dialog_btn" :class="dialogSelect == 'collect'?'selected':''" @click="changeDialogSelect('collect')">
                    我的收藏
                </div>
            </div>
            <div class="collection_list" v-if="collectionList.length" v-loading="tableLoading2">
                <div class="list_item" v-for="(item,i) in collectionList" :key="i">
                    <span v-if="item.company_name_digest != searchCompany1.company_name_digest && item.company_name_digest != searchCompany2.company_name_digest" class="list_left">
                        <span class="company_name" @click="selectedCompany(item)">{{item.company_name}}</span>
                        <img @click="cancelCollect(item.company_name_digest)" v-if="item.collect" src="@/assets/img/search/collected.png" alt="" width="14" height="14">
                        <img @click="addCollect(item.company_name_digest)" v-else src="@/assets/img/search/uncollect.png" alt="" width="14" height="14">
                    </span>
                    <span v-else class="list_left disClick">
                        <span style="cursor: not-allowed">{{item.company_name}}</span>
                        <img @click="cancelCollect(item.company_name_digest)" v-if="item.collect" src="@/assets/img/search/collected.png" alt="" width="14" height="14">
                        <img @click="addCollect(item.company_name_digest)" v-else src="@/assets/img/search/uncollect.png" alt="" width="14" height="14">
                    </span>
                </div>
            </div>
            <no-data v-else>
                <template #img>
                    <img src="@/assets/img/compare/empty.png" alt="" style="width: 187px; height: 144px; margin-bottom: 0">
                </template>
                <template #text>
                    <span style="font-size: 14px;color: #999999;">暂无数据</span>
                </template>
            </no-data>
            <template #footer>
               <span class="dialog-footer">
                  <el-button @click="beforeClose">取 消</el-button>
                </span>
            </template>
        </el-dialog>

        <export_dialog
            @beforeClose="dialogVisible = false"
            @handleExport="handleExport"
            :visible.sync="dialogVisible"
            :all-cnt="parseInt(exportCnt)"
            :surplus="surplus"
            :fast-limit="exportLimit.fast"
            :total-limit="exportLimit.total"
            width="60%">
        </export_dialog>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            text="您当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通"
            width="40%">
        </common_dialog>
    </div>
</template>

<script>
import platformSelector from "../../../components/platformSelector/platformSelector";
import myButton from "../../../components/myButton/myButton";
import listCard from "./listCard";
import * as api from "@/api/compare"
import common_dialog from "../../../components/dialogs/common_dialog";
import export_dialog from "../../../components/dialogs/export_dialog";
import * as echarts from "echarts";
import {optionPie} from './pie_chart_option'
import PinganAna from "pingansec-vue-ana";

  export default {
    name: "index",
    components: {
      myButton,
      platformSelector,
      listCard,
      common_dialog,
      export_dialog
    },
    data() {
      return {
        exportType: '',
        exportLimit: {
          fast: null,
          total: null,
        },
        canCompare: 'empty',
        pieCompany: 'company2',
        searchCompany1: {},
        linkList1: [],
        linkSelectedList1: [],
        searchCompany2: {},
        linkList2: [],
        linkSelectedList2: [],
        newKeyWorld: '',
        searchKeyWords: [],
        changeIndex: null,
        showDialog: false,
        changedCompanyName: '',  //切换公司搜索关键词
        collectionList: [], //我的收藏列表
        tableLoading: false,
        tableLoading2: false,
        searchType: 1,
        tableData: [],
        totalPageConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10],
          layout: 'prev, pager, next',
          count: 5,
          real_cnt: null
        },
        platformList: [],
        searchListKeyWords: [], //用于二次请求的列表关键词数组
        notVipShow: false, //非vip导出提示弹窗
        dialogVisible: false, //导出列表确认弹窗
        surplus: null, //剩余导出次数
        exportCnt: null, //导出数量
        dialogSelect: 'compete', //竞品切换公司弹窗状态切换
        listTimer: null, //列表请求定时器
        dataLoading: false, //正在请求数据
      }
    },
    created() {
      PinganAna.fire(3095)
      if(this.$store.state.userInfo.vip_type != 5) {
        if(this.$route.query.digest1) {
          if(this.$route.query.word) {
            this.searchKeyWords.unshift(this.$route.query.word)
          }
          this.searchCompany1.company_name_digest = this.$route.query.digest1;
          this.searchCompany2.company_name_digest = this.$route.query.digest2;
          this.getCompareBasicData().then(data => {
            this.searchCompany1 = {...this.searchCompany1, ...data.company_a};
            this.searchCompany2 = {...this.searchCompany2, ...data.company_b};
            this.saveCompete()
            this.getListData();
            this.getCompanyLink()
          })
        } else {
          this.getDefaultCompany()
        }
      }
    },
    watch: {
      canCompare(val, oldVal) {
        if(val == 'pie') {
          echarts.dispose(this.$refs['body_chart'])
          this.$nextTick(() =>{
            this.drawPie()
          })
        }
      }
    },
    destroyed() {
      clearInterval(this.listTimer)
    },
    methods: {
      changeDialogSelect(type) {
        this.dialogSelect = type;
        this.getCollectedData()
      },
      changePie(company) {
        this.pieCompany == 'company1'?PinganAna.fire(3124):PinganAna.fire(3123)
        this.pieCompany = company;
        echarts.dispose(this.$refs['body_chart'])
        this.$nextTick(() => {
          this.drawPie()
        })
      },
      drawPie() {
        let that = this;
        let myChart = echarts.init(document.getElementById('body_chart'))
        myChart.setOption(optionPie);
        myChart.setOption({
          title: {
            text:this.pieCompany == 'company1'?this.searchCompany1.company_name:this.searchCompany2.company_name
          },
          series: [{
            data: [
              { value: this.pieCompany == 'company1'?parseInt(this.searchCompany1.sameWordCnt):parseInt(this.searchCompany2.sameWordCnt), name: '相同词占比'},
              { value: this.pieCompany == 'company1'?parseInt(this.searchCompany1.diffWordCnt):parseInt(this.searchCompany2.diffWordCnt), name: '独有词占比'}
            ]
          }]
        })
        window.addEventListener("resize", () => { myChart.resize(); });
        myChart.on('click', function (params) {
          that.exportList('word', params.data)
        })
      },
      tableCellStyle(table) {
        let style = {};
        if(table.column.label !== '关键词') {
          style = {
            'vertical-align': 'top',
          }
        }
        return style;
      },
      linkListChange(val) {
        if(typeof(val) == 'boolean') {
          if(!val) {
            this.saveCompete()
            this.getListData()
          }
        } else {
          this.saveCompete()
          this.getListData()
        }

      },
      platformListChange() {
        this.saveCompete()
        this.getListData()
      },
      handleClose(tag) {
        this.searchKeyWords.splice(this.searchKeyWords.indexOf(tag), 1);
        this.saveCompete()
        this.getListData()
      },
      spreadList(scope) {
        scope.row.company_a = {...scope.row.company_a, spread: true}
        scope.row.company_b = {...scope.row.company_b, spread: true}
        // scope.row.company_a.spread = true;
        // scope.row.company_b.spread = true;
      },
      closeList(scope) {
        scope.row.company_a = {...scope.row.company_a, spread: false}
        scope.row.company_b = {...scope.row.company_b, spread: false}
      },
      exportList(type, obj) {
        this.exportType = {};
        this.exportType = {type, ...obj};
        if(this.exportType.type=='list') {
          PinganAna.fire(3132)
        } else {
          if(this.pieCompany == 'company1') {
            this.exportType.name=='相同词占比'?PinganAna.fire(3127):PinganAna.fire(3128)
          } else {
            this.exportType.name=='相同词占比'?PinganAna.fire(3125):PinganAna.fire(3126)
          }
        }
        if (this.$store.state.userInfo.vip_type == 10) {
          this.notVipShow = true;
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              if(type == 'list') {
                this.exportLimit.fast = 100
                this.exportLimit.total = 100
                this.exportCnt = this.totalPageConfig.real_cnt
              } else {
                this.exportLimit.fast = 20000
                this.exportLimit.total = 200000
                this.exportCnt = obj.value
              }
              this.surplus = res.data.data.total-res.data.data.used;
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleExport(count) {
        if(this.pieCompany == 'company1') {
          this.exportType.name=='相同词占比'?PinganAna.fire(3138):PinganAna.fire(3139)
        } else {
          this.exportType.name=='相同词占比'?PinganAna.fire(3136):PinganAna.fire(3137)
        }
        this.exportType.type=='list' && PinganAna.fire(3133)
        let condition = {
          digests: [
            {
              company_name_digest: this.searchCompany1.company_name_digest,
              link_list: this.linkSelectedList1
            },
            {
              company_name_digest: this.searchCompany2.company_name_digest,
              link_list: this.linkSelectedList2
            }
          ],
          word_list: this.searchKeyWords,
          platform_list: this.platformList
        };
        let word_condition = {
          digest_a: this.pieCompany == 'company1'?this.searchCompany1.company_name_digest:this.searchCompany2.company_name_digest,
          digest_b: this.pieCompany == 'company1'?this.searchCompany2.company_name_digest:this.searchCompany1.company_name_digest
        }
        let params = {
          export_type: this.exportType.type=='list'?'3':
                        this.exportType.name=='相同词占比'?'6':'7',
          export_limit: count,
          export_condition: this.exportType.type=='list'?JSON.stringify(condition):JSON.stringify(word_condition)
        }
        this.dialogVisible = false
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false;
            let export_type = this.exportType.type=='list'?'竞品对比-创意对比': this.exportType.name=='相同词占比'?'竞品对比-相同词占比':'竞品对比-独有词占比';
            let export_title = '';
            if(this.exportType.type=='list') {
              export_title = this.searchCompany1.company_name +'PK'+this.searchCompany2.company_name
            } else {
              if(this.pieCompany == 'company1') {
                if(this.exportType.name=='相同词占比') {
                  export_title = this.searchCompany1.company_name +'相同词（PK'+this.searchCompany2.company_name+'）'
                } else {
                  export_title = this.searchCompany1.company_name +'独有词（PK'+this.searchCompany2.company_name+'）'
                }
              } else {
                if(this.exportType.name=='相同词占比') {
                  export_title = this.searchCompany2.company_name +'相同词（PK'+this.searchCompany1.company_name+'）'
                } else {
                  export_title = this.searchCompany2.company_name +'独有词（PK'+this.searchCompany1.company_name+'）'
                }
              }
            }
            PinganAna.ready(() => {
              PinganAna.userClickLog(
                '数据导出',
                '{"exportType":"'+export_type+'","exportTitle":"'+export_title+'","size":"'+count+'"}',
                '【AI竞投】系统',
                this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                '--',
                this.$store.state.userInfo.account
              )
            })
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      addKeyWorld() {
        if(!this.searchCompany2.company_name_digest) {
          this.$message({
            type: 'error',
            message: '请选择竞品公司！'
          })
          PinganAna.fire(3130)
          return;
        }
        if(this.searchKeyWords.length > 19) {
          this.$message({
            type: 'error',
            message: '最多输入20个关键词！'
          })
          PinganAna.fire(3130)
          return
        }
        if(this.searchKeyWords.indexOf(this.newKeyWorld) > -1) {
          this.$message({
            type: 'error',
            message: '关键词已在筛选列表中！'
          })
          PinganAna.fire(3130)
          return
        }
        if(this.newKeyWorld.replace(/(^\s*)|(\s*$)/g,'')) {
          this.newKeyWorld = this.newKeyWorld.replace(/(^\s*)|(\s*$)/g,'');
          let params = {
            digest_a: this.searchCompany1.company_name_digest,
            digest_b: this.searchCompany2.company_name_digest,
            word: this.newKeyWorld
          }
          api.exist_pk_word(params).then(res => {
            if(res.data.result_code == 0) {
              if(res.data.data.exist) {
                PinganAna.fire(3129)
                this.searchKeyWords.unshift(this.newKeyWorld);
                this.newKeyWorld = '';
                this.saveCompete()
                this.getListData()
              } else {
                PinganAna.fire(3130)
                this.$message({
                  type: 'error',
                  message: '暂未收录此关键词！'
                })
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: '请输入关键词！'
          })
        }

      },
      changeCompany(index, point) {
        if(point) {
          PinganAna.fire(point)
        }
        this.showDialog = true;
        this.changeIndex = index;
        this.getCollectedData();
      },
      beforeClose() {
        this.showDialog = false;
        this.changedCompanyName = '';
        this.dialogSelect = 'compete'
        //更新头部信息
        if(this.searchCompany1.company_name_digest && this.searchCompany2.company_name_digest){
          this.getCompareBasicData().then(data => {
            this.searchCompany1 = {...this.searchCompany1, ...data.company_a};
            this.searchCompany2 = {...this.searchCompany2, ...data.company_b};
            this.saveCompete()
            this.getListData();
            this.getCompanyLink()
          })
        }
      },
      handleSearch() {
        let params = {
          keyword: this.changedCompanyName,
          type: this.searchType
        };
        this.tableLoading2 = true;
        api.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            this.collectionList = res.data.data;
            this.tableLoading2 = false;
          }
        })
      },
      changeSearchType(type) {
        if(type == 2) {
          this.dialogSelect = 'collect'
        }
        this.changedCompanyName = '';
      },
      selectedCompany(item) {
        if(this.changeIndex == 1) {
            this.searchCompany1 = item;
        } else {
          this.searchCompany2 = item;
        }
        this.beforeClose();
      },
      totalPageChange(val) {
        this.totalPageConfig.page = val
        this.getListData();
      },
      totalLimitChange(val) {
        // this.totalPageConfig.page = 1;
        // this.totalPageConfig.limit = val;
        // this.getListData();
      },
      getCompareBasicData() {
        let params = {
            digest_a: this.searchCompany1.company_name_digest,
            digest_b: this.searchCompany2.company_name_digest
        };
        let p = new Promise((resolve, reject) => {
          api.get_pk_basic_info(params).then(res => {
            if(res.data.result_code == 0) {
              resolve(res.data.data);
              this.canCompare = 'pie'
              if(!res.data.data.company_a.hasNAd) {
                this.canCompare = 'no_a'
                if(!res.data.data.company_b.hasNAd) {
                  this.canCompare = 'no_all'
                }
              } else {
                if(!res.data.data.company_b.hasNAd) {
                  this.canCompare = 'no_b'
                }
              }
              if(res.data.data.company_a.sameWordCnt == 0 || res.data.data.company_b.sameWordCnt == 0) {
                this.canCompare = 'zero'
              }
              if(this.canCompare == 'pie') {
                echarts.dispose(this.$refs['body_chart'])
                this.$nextTick(() => {
                  this.drawPie()
                })
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      addCollect(digest) {
        let params = {
          digest
        };
        api.add_collect(params).then(res =>{
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      getCompanyLink() {
        let params = {
          digests: [this.searchCompany1.company_name_digest, this.searchCompany2.company_name_digest]
        }
        let p = new Promise((resolve, reject) => {
          api.get_company_link(params).then(res => {
            if(res.data.result_code == 0) {
                this.linkList1 = res.data.data[this.searchCompany1.company_name_digest];
                this.linkList2 = res.data.data[this.searchCompany2.company_name_digest];
                resolve(res.data.data)
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      cancelCollect(digest) {
        let params = {
          digest
        };
        api.cancel_collect(params).then(res => {
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '取消收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      getAllListData() {
        let params = {
          digests: [
            {
              company_name_digest: this.searchCompany1.company_name_digest,
              link_list: this.linkSelectedList1
            },
            {
              company_name_digest: this.searchCompany2.company_name_digest,
              link_list: this.linkSelectedList2
            }
          ],
          word_list: this.searchListKeyWords,
          platform_list: this.platformList
        };
        this.tableLoading = true;
        let p = new Promise((resolve, reject) => {
          api.get_word_info_by_digests(params).then(res =>{
            if(res.data.result_code == 0) {
              this.tableData = res.data.data
              //添加展开判断字段
              if (this.tableData.length == 1) {
                this.tableData[0].company_a['spread'] = true;
                this.tableData[0].company_b['spread'] = true;
              } else {
                for (var i =0; i < this.tableData.length; i++) {
                  this.tableData[i].company_a['spread'] = false;
                  this.tableData[i].company_b['spread'] = false;
                }
              }
              this.tableLoading = false;
              resolve(this.tableData);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        });
        return p;
      },
      saveCompete() {
        let params = {
          digests : [this.searchCompany1.company_name_digest, this.searchCompany2.company_name_digest],
        }
        let p = new Promise((resolve, reject) => {
          api.save_competitor(params).then(res => {
            if(res.data.result_code == 0) {
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getListData() {
        this.tableData = []
        this.totalPageConfig.total = 0

        let params = {
          digests : [this.searchCompany1.company_name_digest, this.searchCompany2.company_name_digest],
          word_list: this.searchKeyWords,
          page: this.totalPageConfig.page,
          limit: this.totalPageConfig.limit,
        }
        this.dataLoading = true;
        let p = new Promise((resolve, reject) => {
          api.get_pk_word_list(params).then(res => {
            if(res.data.result_code == 0) {
              if(res.data.data.has_same_word) {
                if(res.data.data.reload) {
                  clearInterval(this.listTimer)
                  this.dataLoading = false;
                  this.listTimer = setInterval(() => {
                    this.getListData()
                  }, 10000)
                } else {
                  clearInterval(this.listTimer)
                  this.dataLoading = false;
                  this.tableData = res.data.data.rows;
                  this.searchListKeyWords = this.tableData.map((el) => {
                    return el.same_word;
                  })
                  this.totalPageConfig.total = parseInt(res.data.data.cnt);
                  if(this.totalPageConfig.total > 0) {
                    PinganAna.fire(3131)
                  }
                  this.totalPageConfig.real_cnt = parseInt(res.data.data.real_cnt);
                  this.getAllListData()
                  resolve(res.data.data);
                }
              } else {
                this.tableData = []
                this.totalPageConfig.total = 0
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getCollectedData() {
        let params = {}
        if(this.changeIndex == 2) {
            params = {
              digest: this.searchCompany1.company_name_digest,
              cat: this.dialogSelect == 'compete'?'2':'1'
            }
        } else {
          params = {
            keyword: this.changedCompanyName,
            type: this.searchType
          };
        }
        this.tableLoading2 = true;
        api.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            if(res.data.data) {
              this.collectionList = res.data.data;
            }
            this.tableLoading2 = false;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
            this.tableLoading2 = false;
          }
        })
      },
      getSecondCompany() {
        let params = {
          digest: this.searchCompany1.company_name_digest,
          sort_key: '',
          sort_type: '',
          page: 1,
          limit: 1,
        }
        let p = new Promise((resolve, reject) => {
          api.get_ad_compete_list(params).then(res => {
            if(res.data.result_code == 0) {
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getDefaultCompany() {
        let params = {
          key: 'search_1',
        };
        api.get_val_by_key(params).then(res => {
          if(res.data.result_code == 0) {
            this.searchCompany1 = res.data.data;
            this.searchCompany1.default = true;
            this.getSecondCompany().then(data =>{
              this.searchCompany2 = data.rows[0] || [];
              this.searchCompany2 = {...this.searchCompany2, ...data}
              if(data.cnt != 0) {
                this.getCompareBasicData().then(data =>{
                  this.searchCompany1 = {...this.searchCompany1, ...data.company_a};
                  this.searchCompany2 = {...this.searchCompany2, ...data.company_b};
                  this.saveCompete()
                  this.getListData();
                  this.getCompanyLink()
                })
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      test(a) {
        console.log(a);
      }
    }
  }
</script>

<style scoped lang="scss">
.compare_main {
    height: 100%;
    .canClick {
        cursor: pointer;
    }
    .disClick {
        color: #C0C4CC;
        cursor: not-allowed;
    }
    .gray {
        color: #999999;
    }
    .blue {
        color: #1f81f8;
    }
    .red {
        color: #ef3819;
    }
    .header {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        overflow: hidden;
        .header_title {
            padding: 16px 20px;
            color: #333333;
            font-size: 16px;
            border-bottom: 1px solid #ebeff2;
        }
        .company_group {
            padding: 0 19px;
            .has_border {
                border-bottom: 1px dashed #ebeff2;
            }
            .company_item {
                display: flex;
                justify-content: space-between;
                height: 62px;
                .item_left{
                    display: flex;
                    align-items: center;
                    background-color: #ffffff;
                    color: #999999;
                    font-size: 14px;
                    .company {
                        display: flex;
                        align-items: center;
                    }
                    .text {
                        color: #333333;
                        margin-left: 5px;
                        margin-right: 16px;
                    }
                }
                .item_right {
                    width: 60%;
                    height: 35px;
                    background-color: #f8fafc;
                    border-radius: 5px;
                    border: solid 1px #ebeff2;
                    font-size: 14px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .company_info {
                        color: #333;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
    .compare_group {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        .compare_card {
            display: inline-block;
            width: calc(50% - 7px);
            height: 291px;
            background-color: #ffffff;
            border-radius: 5px;
            border: solid 1px #ebeff2;
            position: relative;
            text-align: center;
            .title {
                padding: 19px;
                font-size: 16px;
                color: #333333;
                border-bottom: 1px solid #ebeff2;
                text-align: left;
            }
            .switch {
                font-size: 12px;
                color: #333333;
                cursor: pointer;
                position: absolute;
                right: 14px;
                top: 78px;
                z-index: 10;
                .left {
                    padding: 6px 11px;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border: solid 1px #ebeff2;
                }
                .right {
                    padding: 6px 11px;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border: solid 1px #ebeff2;
                    /*border-left: none;*/
                }
                .selected {
                    border-color: #1f81f8;
                    color: #1f81f8;
                }
            }
            .body_line {
                display: inline-flex;
                align-items: flex-end;
                padding: 25px 17px;
                .main_info {
                    display: flex;
                    flex-direction: column;
                    .company_name {
                        font-size: 14px;
                        color: #333333;
                        cursor: pointer;
                        max-width: 180px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &:hover {
                            color: #1f81f8;
                        }
                    }
                    .last_time {
                        margin-top: 12px;
                        margin-bottom: 30px;
                        font-size: 12px;
                        color: #999999;
                    }
                }
                .percent_group {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .percent_item {
                        display: inline-flex;
                        align-items: center;
                        margin-bottom: 20px;
                        .percent_line {
                            display: inline-block;
                            height: 5px;
                            border-radius: 3px;
                            animation-name: example;
                            animation-duration: 1s;
                        }
                        @keyframes example {
                            from {width: 0;}
                        }
                        .percent_cnt {
                            font-size: 12px;
                            color: #999999;
                            max-width: 63px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .left_company {
                    .main_info {
                        align-items: flex-end;
                    }
                    .percent_group {
                        align-items: flex-end;
                        .percent_item {
                            flex-direction: row-reverse;
                            .percent_line {
                                background-color: rgba(31, 129, 248, 0.7);
                            }
                            .percent_cnt {
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .center {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    width: 80px;
                    height: 107px;
                    font-size: 12px;
                    color: #999999;
                }
                .right_company {
                    .main_info {
                        align-items: flex-start;
                    }
                    .percent_group {
                        align-items: flex-start;
                        .percent_item {
                            flex-direction: row;
                            .percent_line {
                                background-color: rgba(1, 190, 215, 0.7);
                            }
                            .percent_cnt {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
            #body_chart {
                width: 100%;
                height: 235px;
            }
            .empty_body {
                display: flex;
                flex-direction: column;
                align-items: center;

                font-size: 14px;
                color: #999999;
                .empty_img {
                    margin-top: 39px;
                    width: 187px;
                    height: 144px;
                }

            }
        }
    }
    .list_box {
        width: 100%;
        min-height: calc(100% - 268px);
        margin-top: 10px;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        .title {
            padding: 18px;
            font-size: 16px;
            color: #333333;
            border-bottom: 1px solid #ebeff2;
        }
        .key_words {
            padding:  19px;
            border-bottom: 1px dashed #ebeff2;
            .tag_groups {
                margin-top: 14px;
                .el-tag {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        .list_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            color: #999999;
            font-size: 14px;
            border-bottom: 1px solid #ebeff2;
        }
        .empty_body {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .list_body {
            .table-content{
                .table_company {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #333333;
                    font-size: 16px;
                    margin: 3px 0;
                    .name {
                        margin-bottom: 15px;
                    }
                }
                .empty_table {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 16px;
                    color: #999999;
                    img {
                        margin-top: 100px;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.el-scrollbar .new_button {
    font-size: 14px;
    text-align: right;
    margin-top: 10px;
    margin-right: 10px;
    color: #1f81f8;
    cursor: pointer
}
.list_box {
    .key_words {
        .el-input-group__append {
            background-color: #ffffff;
            font-size: 14px;
            color: #1f81f8;
            i {
                margin-left: 8px;
            }
            &:hover {
                background-color: #1f81f8;
                color: #ffffff;
            }
        }
    }
}

.compare_main .el-checkbox-group
.el-checkbox {
    margin-right: 0;
    .el-checkbox__label {
        /*display: inline-flex;*/
        /*align-items: center;*/
        padding-left: 5px;
        img {
            width: 14px;
            height: 14px;
            margin-right: 3px;
            line-height: 20px;
        }
    }
}


.table_company .el-input--suffix .el-input__inner {
    &::-webkit-input-placeholder {
        color: #333333;
    }
    text-align: center;
}

.compare_main .el-table thead.is-group th {
    color: #333333;
    text-align: center;
}
.compare_main .table-content .el-table {
    color: #333333;
    .el-table__empty-block {
        display: none;
    }
}
.compare_main .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: #fff;
}
.compare_main .my_dialog {
    width:  435px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        border-bottom: 1px solid #ebeff2;
        color: #333333;
        font-size: 16px;
        padding: 20px;
    }
    .el-dialog__body {
        .search_line {
            .el-input-group__prepend {
                width: 93px;
                padding: 0;
                .el-select {
                    margin: -10px -5px;
                }
            }
        }
        .collection_head {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #b5c2d2;
            height: 44px;
            border-bottom: 1px solid #ebeff2;
            box-sizing: border-box;
            img {
                margin-right: 7px;
            }
            .dialog_btn {
                display: inline-block;
                padding: 8px 11px;
                font-size: 14px;
                color: #333333;
                border: solid 1px #cfd5d9;
                border-radius: 3px;
                background-color: #ffffff;
                margin-right: 11px;
                cursor: pointer;
            }
            .selected {
                color: #1f81f8;
                border: solid 1px #1f81f8;
            }
        }
        .collection_list {
            font-size: 14px;
            color: #333333;
            max-height: 200px;
            overflow-y: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none; /* Chrome Safari */
            }
            .list_item {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                .list_left {
                    display: inline-flex;
                    align-items: center;
                    span {
                        cursor: pointer;
                    }
                    .company_name {
                        &:hover {
                            color: #1f81f8;
                        }
                    }
                }
                img {
                    margin-left: 8px;
                    cursor: pointer;
                }
                .list_right {
                    .el-checkbox__inner {
                        border-radius: 60%;
                    }
                    .el-checkbox__input.is-disabled+span.el-checkbox__label {
                        color: #2a87f8;
                    }
                    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
                        background-color: #409EFF;
                        border-color: #409EFF;
                    }
                }
            }
        }
    }
    .el-dialog__footer {
        padding: 12px 15px;
        border-top: 1px solid #ebeff2;
    }
}
</style>
