import http from '@/utils/http'

// 获取默认公司
export function get_val_by_key(data) {
  return http({
    url: '/u-base/ac-get_val_by_key',
    method: 'get',
    params: data
  })
}

//搜索页公司详情
export function get_ad_compete_list(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_compete_list',
    method: 'get',
    params: data
  })
}

//竞品对比-基础信息获取
export function get_pk_basic_info(data) {
  return http({
    url: '/u-adsearch/ac-get_pk_basic_info',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//按公司找竞品切换公司
export function get_ad_compete_list_search(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_compete_list_search',
    method: 'get',
    params: data
  })
}

// 竞品对比-增加竞品词
export function exist_pk_word(data) {
  return http({
    url: '/u-adsearch/ac-exist_pk_word',
    method: 'get',
    params: data
  })
}

//竞品对比-关键词列表查寻
export function get_pk_word_list(data) {
  return http({
    url: '/u-adsearch/ac-get_pk_word_list',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//竞品对比-公司推广链接最大500个
export function get_company_link(data) {
  return http({
    url: '/u-adsearch/ac-get_company_link',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//竞品对比-按公司获取列表数据
export function get_word_info_by_digests(data) {
  return http({
    url: '/u-adsearch/ac-get_word_info_by_digests',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//竞品对比-获取列表数据（单个词）
export function get_word_list_info_by_digests(data) {
  return http({
    url: '/u-adsearch/ac-get_word_list_info_by_digests',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

export function ge_export_task(data) {
  return http({
    url: '/u-base/ac-ge_export_task',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

// 加入收藏
export function add_collect(data) {
  return http({
    url: '/u-base/ac-add_collect',
    method: 'get',
    params: data
  })
}
// 取消收藏
export function cancel_collect(data) {
  return http({
    url: '/u-base/ac-cancel_collect',
    method: 'get',
    params: data
  })
}

// 设为默认公司
export function set_val_by_key(data) {
  return http({
    url: '/u-base/ac-set_val_by_key',
    method: 'get',
    params: data
  })
}

//可导出数据查询
export function get_last_export_cnt(data) {
  return http({
    url: '/u-base/ac-get_last_export_cnt',
    method: 'get',
    params: data
  })
}

//竞品对比-存储用户关注竞品公司
export function save_competitor(data) {
  return http({
    url: '/u-adsearch/ac-save_competitor',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}
