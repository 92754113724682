<template>
    <div class="list_card_main">
        <el-collapse-transition>
            <div class="default" v-if="data.has_data && !isSpread">
                <div class="card_title" v-html="data.title_em" @click="jump(data.land_url)"></div>
                <div class="content">{{data.content}}</div>
                <div class="footer">
                    <span>{{data.domain}}</span>
                    <span>{{data.lastest_date}}</span>
                    <span class="platform">{{$C.platformTurn(data.platform)}}</span>
                </div>
                <div class="get_more" @click="spreadList" v-if="data.cnt > 1">
                    <i class="el-icon-arrow-down"></i>
                    <span>查看更多 </span>
                    <span class="count">{{data.cnt}}</span>
                </div>
            </div>
        </el-collapse-transition>
        <el-collapse-transition>
            <div class="card_group" v-if="isSpread">
                <div class="card_item" v-for="(item, i) in tableList" :key="i" :class="i == tableList.length-1?'no_border':''">
                    <div class="card_title" v-html="item.title_em" @click="jump(data.land_url)"></div>
                    <div class="content">{{item.content}}</div>
                    <div class="footer">
                        <span>{{item.domain}}</span>
                        <span>{{item.lastest_date}}</span>
                        <span class="platform">{{$C.platformTurn(item.platform)}}</span>
                    </div>
                    <div class="page_box" v-if="i == tableList.length-1 && data.cnt > 1">
                        <span class="close" @click="closeList">
                            <i class="el-icon-arrow-up" ></i>
                            <span>收起 </span>
                            <span class="count">{{data.cnt}}</span>
                        </span>
                        <page  :config="pageConfig" @pageChange="pageChange" @limitChange="limitChange"></page>
                    </div>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>

<script>
import * as api from "@/api/compare"
import PinganAna from "pingansec-vue-ana";
  export default {
    name: "listCard",
    props: {
      data: {
        type: Object,
      },
      getParams: {
        type: Object,
      },
      isSpread: Boolean
    },
    data() {
      return {
        mySpread: false,
        tableList: [],
        pageConfig: {
          page: 1,
          limit: 5,
          total: null,
          sizes: [5],
          layout: 'prev, pager, next',
          count: 5,
        },
      }
    },
    mounted() {
      if(this.isSpread) {
        this.getMore()
      }
    },
    watch: {
      isSpread(newVal, oldVal) {
        this.mySpread = newVal;
        if(this.mySpread) {
          this.getMore()
        }
      }
    },
    methods: {
      jump(url) {
        PinganAna.fire(3134)
        let re = new RegExp( /(http|https):\/\/([\w.]+\/?)\S*/);
        if(!re.test(url)) {
          url = 'http://' + url;
        }
        window.open(url, '_blank');
      },
      pageChange(val) {
        this.pageConfig.page = val
        this.getMore();
      },
      limitChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.limit = val;
        this.getMore();
      },
      closeList() {
        this.$emit('closeList')
      },
      spreadList() {
        this.$emit('spreadList')
      },
      getMore() {
        let params = {
          digests: [
            {
              company_name_digest: this.data.company_name_digest,
              link_list: this.getParams.link_list,
              page: this.pageConfig.page,
              limit: this.pageConfig.limit
            }
          ],
          word_str: this.getParams.word_str,
          platform_list: this.getParams.platform_list
        }
        api.get_word_list_info_by_digests(params).then(res =>{
          if(res.data.result_code == 0) {
            this.tableList = res.data.data.rows;
            this.pageConfig.total = res.data.data.cnt;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.list_card_main {
       .card_title {
           font-size: 16px;
           line-height: 20px;
           height: 20px;
           color: #207ff7;
           text-decoration: underline #207ff7;
           overflow: hidden;
           text-overflow: ellipsis;
           cursor: pointer;
           white-space: nowrap;
       }
       .content {
           margin-top: 10px;
           color: #333333;
           font-size: 14px;
           line-height: 20px;
           height: 40px;
           word-break: break-all;
           text-overflow: ellipsis;
           display: -webkit-box;
           -webkit-box-orient: vertical;
           -webkit-line-clamp: 2;
           overflow: hidden;
       }
       .footer {
           height: 25px;
           margin-top: 10px;
           color: #999999;
           span {
               margin-right: 10px;
           }
           .platform {
               font-size: 12px;
               padding: 3px;
               color: #7dacfa;
               border: solid 1px #7dacfa;
           }
       }
       .get_more {
           margin-top: 13px;
           cursor: pointer;
           color: #666666;
           i {
               margin-right: 4px;
           }
           .count {
               color: #ef3819;
           }
       }
    .default {}
    .card_group {
        .no_border {
            border-bottom: none !important;
        }
        .card_item {
            height: 150px;
            border-bottom: 1px solid #ebeff2;
            margin-bottom: 19px;
            .page_box {
                display: flex;
                width: 98%;
                justify-content: space-between;
                position: absolute;
                bottom: 10px;
                .close {
                    cursor: pointer;
                }
                i {
                    margin-right: 4px;
                }
                .count {
                    color: #ef3819;
                }
            }
            #page {
                display: inline-flex;
                padding: 0;
                height: auto;
                width: auto;
            }
        }
    }
}
</style>

<style>
.card_title em {
    color: #ef3819;
    text-decoration: underline #ef3819;
}
</style>
