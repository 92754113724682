export const optionPie = {
  color: ["rgba(31, 129, 248, 0.7)", "rgba(1, 190, 215, 0.7)"],
  title: {
    top: 38,
    left: 20,
    textStyle: {
      fontWeight: 'normal',
      fontSize: 14,
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)'
  },
  series: [
    {
      name: '关键词竞争',
      type: 'pie',
      center: ['50%','60%'],
      radius: ['28%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {
        show: true,
        minTurnAngle: 100
      },
      label: {
        formatter: '{b|{b}}  {per|{d}%} \n {c|{c}}个 {button|下载}',
        rich: {
          b: {
            color: '#999999',
            fontSize: 12,
            lineHeight: 20
          },
          per: {
            color: '#999999',
            fontSize: 12,
            lineHeight: 20
          },
          c: {
            color: '#333333',
            fontSize: 12,
            lineHeight: 20
          },
          button: {
            color: '#1f81f8',
            fontSize: 12,
            lineHeight: 20
          }
        }
      },
      data: []
    }
  ]
}
